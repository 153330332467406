<template>
  <div class="society">
    <div class="content">
      <p class="section">การนับเครือญาติ ในพื้นที่ตำบลศรีถ้อย</p>

      <p class="section-secondary">
        ในพื้นที่ตำบลศรีถ้อยประกอบไปด้วยสามสกุลหลัก ได้แก่
      </p>
      <div class="section-list">
        <p>
          ตระกูลที่ 1 ภาชนนท์ เอ่ยนามคนเเรกคือ พ่อจันทร์ ภาชนนท์
          ซึ่งเสียชีวิตแล้ว มีบุตรธิดารวม 4 คน
        </p>
        <p>ตระกูลที่ 2 ตระกูลปันสุวรรณ มีบุตรธิดารวม 3 คน</p>
        <p>ตระกูลที่ 3 ใจมิภักดิ์ มีบุตรธิดารวม 3 คน</p>
      </div>

      <div class="section-image">
        <el-image :src="require('../../assets/society/1.jpg')" fit="cover" />
      </div>

      <p class="section">
        ตารางแสดงข้อมูลปราชญ์ชุมชนตำบลศรีถ้อย อำเภอแม่ใจ จังหวัดพะเยา
      </p>
      <div class="section-table">
        <el-table :data="tableData" border>
          <el-table-column
            prop="name"
            label="ชื่อ-สกุล"
            header-align="center"
            width="200"
          >
          </el-table-column>
          <el-table-column
            prop="expertise"
            label="ความเชี่ยวขาญ"
            header-align="center"
            width="160"
          >
          </el-table-column>
          <el-table-column
            prop="address"
            label="ที่อยู่"
            header-align="center"
            align="center"
            width="80"
          >
          </el-table-column>
          <el-table-column
            prop="description"
            label="รายละเอียด"
            header-align="center"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          name: "1.คุณสายรุ้ง ติณราช",
          expertise: "ทำสมุนไพรด้านสุขภาพ",
          address: "อยู่หมู่2",
          description: "การรักษาแบบแพทย์แผนไทย ทำลูกประคบ ทำแบบรวมกลุ่มชุมชน",
        },
        {
          name: "2..นายสมนัด มูลพิยะเทพ",
          expertise: "ทำเศรษฐกิจพอเพียง/โคกหนองนาโมเดล",
          address: "อยู่หมู่2",
          description: "ทำในครอบครัว มีลักษณะเศรษฐกิจระดับครัวเรือน",
        },
        {
          name: "3.นายอินศร อภิวงศ์",
          expertise: "ทำเกษตรอินทรีย์ ปลูกผักสวนครัว",
          address: "อยู่หมู่5",
          description:
            "ปลูกหลายอย่าง ไม่ว่าจะเป็น ผักกาดหอม พริก ต้นหอม กะหล่ำ ปลูกไว้กินเองเหลือก็เอาไปขาย ",
        },
        {
          name: "4.นายสัมฤทธิ์ เมืองมูล และนาย เรือง ชัยก๋า",
          expertise: "หมอชาวบ้าน",
          address: "อยู่หมู่6",
          description:
            "หมอเป่า สืบทอดวิชาตั้งแต่รุ่นปู่ย่าตายายเป็นการรักษาโรคที่เป็นความเชื่อส่วนบุคคล ชาวบ้านมารักษา โรคงูสวัดหาย",
        },
        {
          name: "1.คุณสายรุ้ง ติณราช",
          expertise: "ทำสมุนไพรด้านสุขภาพ",
          address: "อยู่หมู่2",
          description: "การรักษาแบบแพทย์แผนไทย ทำลูกประคบ ทำแบบรวมกลุ่มชุมชน",
        },
        {
          name: "5.นางขันแก้ว วงศ์ลาจา",
          expertise: "เป๋นหมอดู",
          address: "อยู่หมู่6",
          description:
            "หมอดู สืบทอดวิชาตั้งแต่รุ่นปู่ย่าตายายเป็นการรักษาโรคที่เป็นความเชื่อส่วนบุคคล",
        },
        {
          name: "6.นางศรี เวียงนาค",
          expertise: "ทำจักรสานเส้นพลาสติก",
          address: "อยู่หมู่6",
          description: "ประดิษฐ์สิ่งของจากขยะรีไซเคิล รวมกลุ่มกันในชุมชน",
        },
        {
          name: "7.ป้าคำแดง โถยะโล",
          expertise: "ทำสานไม้กวาด / ห่วงใส่แก้วน้ำ",
          address: "อยู่หมู่6",
          description: "ทำไม้กวาดแบบรวมกลุ่มกันในชุมชน",
        },
        {
          name: "8.พ่อหลวงบุญหลง ร่องอ้อ",
          expertise: "ทำมะพร้าวเผา",
          address: "อยู่หมู่7",
          description: "รับซื้อมะพร้าวแล้วมาทำเอง ทำอยู่ในระดับครัวเรือน",
        },
        {
          name: "9.พ่อหลวงสมสิทธิ์ แซ่จ๋าว และ พ่อหลวงรัฐศาสตร์",
          expertise: "ทำกาแฟ",
          address: "อยู่หมู่10และ13",
          description:
            "ทำตั้งแต่ ปลูก ตาก  คั่ว และก็ขาย ที่ปางปูเลาะ เป็นสถานที่ท่องเที่ยวที่พัก จุดชมวิว ในชุมชนแทบทุกบ้านปลูกต้นกาแฟ เพราะสร้างรายได้ เป็นกลุ่มวิสาหกิจฯ",
        },
        {
          name: "10.นายนิคม ใจมิภักดิ์",
          expertise: "ทำผลิตภัณฑ์น้ำปู",
          address: "อยู่หมู่11",
          description:
            "ทำกันเองในระดับครัวเรือน ทำขาย รับซื้อปูจากชาวบ้านที่เก็บมาขาย แล้วก็ไปทำน้ำปู ",
        },
        {
          name: "11.คุณจ่า",
          expertise: "ทำปุ๋ยหมักชีวภาพ",
          address: "อยู่หมู่12",
          description:
            "เอาซากพืชซากสัตว์มาหมัก ใส่ขวด ทำใช้เอง ทำแจกคนในชุมชน ตลอดจนสอนและถ่ายถอดผู้อื่น",
        },
        {
          name: "12.คุณศรีมูล แสงสุวรรณ",
          expertise: "ทำสานไม้ไผ่",
          address: "อยู่หมู่12",
          description: "สานตระกร้า ซุ่ม เป็นกลุ่มวิสาหกิจฯ",
        },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.society {
  .content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;

    .section {
      font-weight: 600;
    }
    .section-secondary {
      font-weight: 600;
      padding-left: 20px;
    }
    .section-list {
      padding-left: 40px;
      text-indent: 2em;
    }
    .section-image {
      display: flex;
      align-content: center;
      justify-content: center;
      .el-image {
        height: 500px;
      }
    }
  }
}
</style>