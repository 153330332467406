<template>
  <div class="resource">
    <div class="content">
      <p class="section">ด้านทรัพยากรธรรมชาติ</p>
      <p class="section-secondary">
        1.ทรัพยากรน้ำ แหล่งน้ำในชุนชน
        ทรัพยากรน้ำ/สัตว์น้ำที่พบมากในชุมชนท่านได้แก่
      </p>
      <div class="section-list">
        <p>
          - แหล่งน้ำในชุมชนจะมีอ่างเก็บน้ำห้วยชมพู อ่างเก็บน้ำห้วยป่ากล้วย
          (อ่างแม่ใจ) และหนองเล็งทรายที่นำมาใช้ในการทำการเกษตร
          รวมถึงเป็นพื้นที่ต้นน้ำ
          นอกจากนี้หนองเล็งทรายเป็นจุดขายของตำบลและเป็นต้นน้ำของน้ำกว๊านพะเยา
          และยังมีคลองชลประทานที่ไหลมาจากจังหวัดเชียงรายมาในตำบลศรีถ้อย
          สัตว์น้ำที่พบส่วนมากจะเป็นปลา กุ้ง หอย และอื่น ๆ
        </p>
      </div>
      <div class="section-image">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/1.jpg')"
              fit="cover"
            />
          </el-col>
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/2.jpg')"
              fit="cover"
            />
          </el-col>
        </el-row>
      </div>

      <p class="section-secondary">
        2.ทรัพยากรป่าไม้ พื้นที่ป่าไม้ในชุมชน ต้นไม้
        หรือพืชพรรณที่มีมากในชุมชนของท่านได้แก่
      </p>
      <div class="section-list">
        <p>
          - การท่องเที่ยวในระบบนิเวศนั้น มีปางปูเลาะเป็นโฮมสเตย์
          เพื่อใช้ในการรองรับนักท่องเที่ยวที่มาชมวิวดูทรัพยากรต้นไม้วิวภูเขาที่มีระบบนิเวศที่เย็นสบาย
          ซึ่งเป็นจุดชมวิว และเป็นแลนด์มาร์คที่สำคัญของตำบลศรีถ้อย
          อีกทั้งมีการเพาะปลูกกาแฟที่ขึ้นชื่อของอำเภอแม่ใจ คือ กาแฟปางปูเลาะ
          ซึ่งปลูกในพื้นที่ปางปูเลาะที่มีความสูงจากระดับน้ำทะเลมากกว่า 700 เมตร
          และในพื้นที่ตำบลศรีถ้อยมีการปลูกลิ้นจี่ แคนตาลูบ
          และมีจุดที่วางขายของฝากจากพี่น้องเกษตรกรในตำบลศรีถ้อยที่มีความโดดเด่นกับผลิตภัณฑ์หัตถกรรมหรืองานฝีมือต่าง
          ๆ ของพี่น้องเกษตรกร โดยจะตั้งอยู่ที่สามแยกไฟแดงหลักของพื้นที่นี้
        </p>
      </div>
      <div class="section-image">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/3.jpg')"
              fit="cover"
            />
          </el-col>
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/4.jpg')"
              fit="cover"
            />
          </el-col>
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/5.jpg')"
              fit="cover"
            />
          </el-col>
        </el-row>
      </div>

      <p class="section-secondary">
        3.ทรัพยากรพืชพรรณ ตามธรรมชาติ และที่เพาะปลูก ต้นไม้
        หรือพืชพรรณที่ท่านปลูกในครัวเรือนของท่านได้แก่
      </p>
      <div class="section-list">
        <p>
          - ลิ้นจี่ GI พันธุ์ฮงฮวย
          ต้นกำเนิดลิ้นจี่ในพื้นที่ตำบลศรีถ้อยเริ่มที่ห้วยป่ากล้วย โดยการนำของ
          อาจารย์ทองคำ สารถ้อย อาจารย์สอนอยู่ที่โรงเรียนบ้านทุ่งป่าข่า
          (โรงเรียนบ้านทุ่งป่าข่าตั้งอยู่ที่ บ้านทุ่งป่าข่าหมู่6 ตำบลศรีถ้อย
          อำเภอแม่ใจ ) มีแนวความคิดว่าจะหาพืชเศรษฐกิจชนิดใดมาปลูกในพื้นที่
          จึงได้ปรึกษาหารือกับทางอำเภอแม่ใจ และจัดทำโครงการ
          ซึ่งในขณะนั้นมีนายทองคำ เขื่อนทา เป็นนายอำเภอแม่ใจและนายสุธน
          สร้อยแสงจันทร์ เป็นเกษตรอำเภอแม่ใจ
          ได้จัดทำโครงการขอสนับสนุนกิ่งตอนลิ้นจี่ “พันธ์ฮงฮวย”
          จากสำนักงานเกษตรจังหวัดเชียงราย จำนวน 30 ต้น
          มาปลูกในโรงเรียนบ้านทุ่งป่าข่า หมู่ที่ 6 ตำบลศรีถ้อย อำเภอแม่ใจ
          โดยใช้ระยะปลูก 12 x 12 เมตร
          เพื่อเป็นจุดสาธิตและขยายพันธ์ให้กับเกษตรกรต่อมา นายจันทร์ ภาชนนท์
          ได้ขยายพันธ์โดยการตอนไปปลูกสวนที่อยู่ใน ห้วยป่ากล้วย
          (ปัจจุบันเป็นอ่างเก็บน้ำ ) ปรากฏว่าให้ผลผลิตดี รสชาติ อร่อย
          สามารถจำหน่ายได้ราคาดี ประมาณปี พ.ศ. 2520 เกษตรกรบ้านทุ่งป่าข่า
          จึงเริ่มขยายพันธ์ปลูกกันมากขึ้น และได้แพร่หลายไปยังหมู่บ้านอื่นๆ
          ตั้งแต่นั้นมา มีการปลูกขึ้นเรื่อย ๆ
          จนมีชื่อสียงเป็นที่รู้จักทั่วไปและทางภาคส่วนราชการได้ประชาสัมพันธ์
          โดยมีการจัดงาน “เทศกาลลิ้นจี่และของดีจังหวัดพะเยา ” ขึ้นเป็นปะจำทุกปี
          เพื่อเผยแพร่ชื่อเสียงลิ้นจี้แม่ใจของจังหวัดพะเยา
          ให้เป็นที่รู้จักแพร่หลายมากยิ่งขึ้น
        </p>
        <p>
          ลิ้นจี่ทำตำบลศรีถ้อยจะมีความเป็นอัตลักษณ์เอกลักษณ์เฉพาะตัว คือ “ไหล่ยก
          อกตั้ง ร่องลึก หนามสั้น ก้นป้าน เนื้อกอด ในแห้ง กลิ่นหอมและหวาน”
          เนื่องจากสภาพภูมิอากาศและภูมิประเทศที่เอื้ออำนวย
          จึงทำให้ลิ้นจี่ของตำบลศรีถ้อย และอำเภอแม่ใจ มีรสชาติหวาน เนื้อแห้ง หอม
          อร่อย ทำให้ผู้บริโภคสามารถรับประทานได้จำนวนมากกว่า ไม่มีอาการร้อนใน
        </p>
        <p>
          นอกจากลิ้นจี่เกษตรกรยังมีการเพาะปลูกพืชชนิดอื่น ๆ ในทุก ๆ
          ฤดูการไม่ว่าจะเป็น มะพร้าว แตง และแคนตาลูป
        </p>
      </div>
      <div class="section-image">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/6.jpg')"
              fit="cover"
            />
          </el-col>
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/7.jpg')"
              fit="cover"
            />
          </el-col>
        </el-row>
      </div>

      <p class="section-secondary">
        4.ทรัพยากรสิ่งมีชีวิตต่าง ๆ สัตว์ตามธรรมชาติที่มีมากในชุมชนของท่านได้แก่
      </p>
      <div class="section-list">
        <p>
          - นกยูง จะมีเขตพื้นที่ที่อาศัยอยู่เป็นประจำ
          ข่วงนกยูงอยู่ในเขตป่าหรือเขตภูเขาเป็นส่วนใหญ่ แต่พอตกช่วงเย็น
          นกยูงจะออกมาหาอาหารแถวพื้นที่แหล่งน้ำ เช่น อ่างเก็บน้ำห้วยป่ากล้วย
          ซึ่งในตำบลศรีถ้อยก็จะมีนกยูงที่อยู่ในเขตตำบลเยอะ ประมาณเกือบ 20 ตัว
          สัตว์อีกประเภทที่มีมากในพื้นที่ คือ
          นกปากห่านจะมาในช่วงฤดูเก็บเกี่ยวข้าว
        </p>
      </div>
      <p class="section-secondary">5.สัตว์ที่ท่านเลี้ยงในครัวเรือน ได้แก่</p>
      <div class="section-list">
        <p>
          - หมู หมา ไก่ แมว ซึ่งหมู
          ไก่เลี้ยวเพื่อใช้ในการประกอบอาหารและบางทีมีเหลือก็นำจัดจำหน่าย
          ส่วนหมาแมวเลี้ยงเพื่อความสุข รัก หรือความชอบส่วนตัว
        </p>
      </div>

      <p class="section-secondary">6.ทรัพยากรอื่น ๆ ในชุมชนท่าน ได้แก่</p>
      <div class="section-list">
        <p>
          - ทรัพยากรด้านแหล่งวัฒนธรรมก็จะมีถ้ำประกายดาว
          ผาแดงเป็นผาหินสีแดงใหญ่และเป็นจุดชมวิว วัดศรีบุญเรือง วัดโพธาราม
          วัดฝายหิน ซึ่งวัดสามวัดนี้เป็นวัดที่อยู่ในตำนานประวัติศาสตร์
          ทั้งวัดโพธารามและวัดศรีบุญเรืองก็เป็นวัดเก่าแก่ที่มีพระคู่บ้านคู่เมือง
          คือ
          พระเจ้าองค์ดำถ้าเปรียบเทียบกันก็คือจะเป็นพี่น้องของพระเจ้าทองทิพย์ที่อยู่ที่วัดพระศรีสุนันท์
          ซึ่งจะอยู่เขตตำบลแม่ใจ มีพระเจ้าฝนแสนฮ่า
          ทุกองค์จะมีประวัติความเป็นไปเป็นมา ในตำบลศรีถ้อยจะมีทั้งแหล่งวัฒนธรรม
          แหล่งท่องเที่ยว แหล่งที่พึ่งทางใจ
          นอกจากนั้นตำบลศรีถ้อยก็ถือว่าเป็นหมู่บ้านที่มีเศรษฐกิจที่ดีที่สามารถพึ่งพาตนเองได้
        </p>
      </div>
      <div class="section-image">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-image
              :src="require('../../assets/resource/8.jpg')"
              fit="cover"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>


<style lang="scss" scoped>
.resource {
  .content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    .title {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
    }
    .section {
      font-weight: 600;
    }
    .section-secondary {
      font-weight: 600;
      padding-left: 20px;
    }
    .section-list {
      padding-left: 40px;
      text-indent: 2em;
    }
    .section-image {
      display: flex;
      align-content: center;
      justify-content: center;
      padding-left: 40px;
      padding-right: 40px;
      .el-row {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        .el-col {
          padding-bottom: 20px;
          height: 250px;
          .el-image {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>