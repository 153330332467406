<template>
  <div class="legend">
    <div class="content">
      <p class="section">
        1. ตำนาน เรื่องเล่า เกี่ยวกับประวัติความเป็นมาของชุมชน
      </p>
      <p class="section-secondary">- พ่อหลวงคำแดง</p>
      <div class="section-list">
        <p>
          ตามประวัติชาวบ้านเชื่อว่าเป็นที่พึ่งทางใจตั้งแต่ 100
          ปีภาคเหนือมีเกือบทุกจังหวัด ทุกปีชาวบ้านจะมาบวงสรวงปีละสองครั้ง
          และมาขอพรเพื่อให้คุ้มครองให้ขับขี่ปลอดภัยและมาขอในเรื่องการสอบแต่งตั้งต่าง
          ๆ และช่วยปกป้องด้านต่าง ๆ ทั้งในด้านการงาน อาชีพ อุบัติเหตุ ฯลฯ
          หากว่าเรื่องที่ขอนั้นเป็นผลก็จะมีการแก้บนคือนำหมูหรือไข่
          มาแก้บนตอนทำพิธีฯ
          ศาลพ่อหลวงคำแดงจะอยู่ตรงเลยไฟแดงหลักของตำบลศรีถ้อยไปประมาณ 200
          เมตรแล้วก็มาสร้างตรงอำเภอ
        </p>
      </div>
      <p class="section-secondary">- ตำนานพระธาตุจอมก้อ</p>
      <div class="section-list">
        <p>
          เป็นศูนย์รวมจิตใจของตำบลศรีถ้อย
          ลูกศิษย์ครูบาศรีวิชัยเป็นผู้สร้างพระธาตุ
          และได้นำเอาแก้วแหวนหรือทองใส่ไปในนั้นด้วย ณ ขณะนี้มีอายุประมาณ 100 ปี
          ในปัจจุบันมีการบูรณะขึ้นมาใหม่
        </p>
      </div>
      <p class="section-secondary">- พระพุทธรูปพระเจ้าองค์ดำ</p>
      <div class="section-list">
        <p>
          ตั้งอยู่ที่วัดศรีบุญเรืองเป็นที่สักการะบูชาของชาวบ้านตำบลศรีถ้อย
          และจะมีการบวงสรวง สรงน้ำพระเจ้าองค์ดำทุก ๆ ปี
        </p>
      </div>
      <div class="section-image">
        <el-image :src="require('../../assets/legend/1.jpg')" fit="cover" />
      </div>
      <p class="section-secondary">- พระเจ้าทองสัมฤทธิ์</p>
      <div class="section-list">
        <p>
          ตั้งอยู่ที่วัดศรีบุญชุม ชาวบ้านจะไปขอพร คือการขอฝน
          จะมีการทำพิธีเชิญลงจากแท่น เป็นพระพุทธรูปที่มีค่าทางเศรษฐกิจ
        </p>
      </div>
      <div class="section-image">
        <el-image :src="require('../../assets/legend/2.jpg')" fit="cover" />
      </div>
      <p class="section-secondary">- ทำนานหนองเล็งทราย</p>
      <div class="section-list">
        <p>
          แต่ก่อนเป็นที่ชุ่มและเป็นที่หากินของคนที่อยู่บริเวณนั้นและชาวบ้านมักจะจับปลากินกันและมีอยู่วันหนึ่งมีคนในหมู่บ้านนั้นไปจับปลาไหลได้เป็นปลาไหลเผือกตัวใหญ่
          ถ้าเป็นคนอีสานก็จะเชื่อว่าเป็นพญานาค
          จนมีอยู่วันหนึ่งเอาปลาไหลตัวนั้นมาแบ่งกันกินและทุกคนในหมู่บ้านนั้นก็กินหมดทุกคนแต่ไม่แบ่งให้แม่ม้ายคนนั้นกินเพราะว่าแม่ม้ายคนนั้นสวยพ่อบ้านไปแอบชอบหญิงม้ายคนนั้นจึงทำให้คนในหมู่บ้านนั้นไม่ชอบแม่ม้ายคนนี้พอถึงยามดึกหมู่บ้านแห่งนี้เกิดอาเพศแผ่นดินไหวแผ่นดินยุบตนในชุมชนนั้นจึงหายไปทั้งหมดแต่เหลือไว้แต่เพียงบ้านแม่ม้ายนั้นไว้จนปัจจุบันจึงเรียกว่าดอนแม่ม้าย
        </p>
      </div>
      <p class="section">
        2. ตำนาน เรื่องเล่า เกี่ยวกับความเชื่อ และพิธีกรรมในชุมชน
      </p>
      <p class="section-secondary">- ความเชื่อเรื่องผี</p>
      <div class="section-list">
        <p>
          อดีตเคยมีผีกะ ผีโพง ผีกะสือ แต่ผีดีคือผีโพงกับผีกะสือ
          ส่วนผีกะนั้นคือผีไม่ดี
          ในอดีตผีกะจะมีเยอะใครที่เป็นสาวสวยๆจะถูกใส่ร้ายว่าเป็นผีกะและเป็นที่รังเกียจของคนในชุมชนนั้นและถ้าบ้านหลังไหนมีนกเค้าแมวไปเกาะ
          คนในหมู่บ้านจะคิดว่าสาวบ้านนั้นเป็นผีกะ
          และไม่กล้ามีใครไปเที่ยวบ้านหลังนั้น
          สมัยก่อนเวลาจะไปจีบสาวต้องไปในตอนกลางคืน
          เพราะว่าไปกลางวันต้องทำงานและถ้าไปตอนกลางวันพ่อแม่ฝ่ายผู้หญิงจะมาว่าขี้เกียจไม่ช่วยพ่อแม่ทำงาน
        </p>
        <p>- อาชีพ > เจ้าหลวงคำแดง</p>
        <p>มีการมาบนขอพรในการประกอบอาชีพต่างๆ และมีการขอพรปีละ 2 ครั้ง</p>
        <p>- ชีวิต > ผีบรรพบุรุษ</p>
        <p>
          พิธีตัดความสัมพันธ์ คนที่ตายทั้งกลม
          ให้พี่น้องลอดตาแหลวแล้วใช้หัวปลีแล้วเอามีดมาตัด แล้วห้ามหันกลับไป
          เพราะกลัวว่าเขาจะเอาไปอยู่ด้วยเลยตัดไม่ให้ผูกพันธ์กัน (หัวปลี อาจแทนคน
          เช่นคนตายทั้งกลมก็ต้องผ่าเอาลูกออกก่อน แล้วเอาหัวปลีใส่ไปแทน
          ถ้าไม่เอาลูกออกลูกก็จะไม่ได้ไปเกิด) และถ้าเสียชีวิตจากอุบัติเหตุ
          ก็จะเอาพระไป 4 องค์ แล้วก็มีการก่อเจดีย์ทราย 100 กอง เหรียญ 100 บาท
          เป็นการเรียกวิณญาณให้กลับบ้าน
        </p>
        <p>ผีปู่ย่า คือ การเลี้ยงผีบรรพบุรุษที่ล่วงลับไปแล้ว</p>
      </div>

      <p class="section-secondary">- ความเชื่อเรื่องเทวดา</p>
      <div class="section-list">
        <p>
          ขึ้นบ้านใหม่ งานบุญต่างๆ การเกิด การแต่งงาน การทำศพ การเรียกวิญญาณ
          ต้องมีการบอกกล่าวท้าวทั้งสี่ผู้ที่ควบคุมทั้ง 4 ทิศ
          เพื่อให้งานราบรื่นและสำเร็จผล
        </p>
      </div>

      <p class="section-secondary">- ความเชื่อเกี่ยวกับประเพณี</p>
      <div class="section-list">
        <p>
          การตานข้าวใหม่
          เมื่อทำนาทำไร่เสร็จเราได้กินข้าวใหม่ก็จะนึกถึงบรรพบุรุษที่ล่วงลับไปแล้วและตอบแทนบุญคุณไม่ให้ลืมอย่างเช่นนาที่เราทำอยู่ก็อาจจะเป็นของพ่อของแม่ที่ตายไปแล้วเลยต้องทำบุญไปหาเขา
          ซึ่งเป็นการระลึกตอบแทนบุญคุณแสดงถึงความกตัญญูของคนในพื้นที่ในบ้าน
        </p>
        <p>ตานก๋วยสลาก เป็นการทำบุญหาคนที่ล่วงลับไปแล้ว</p>
        <p>
          การเลี้ยงผีชาวบ้าน
          คือการเลี้ยงเจ้าที่ในหมู่บ้านจะไปทำตามใต้ต้นไม้ใหญ่ๆ
        </p>
        <p>ผีปู่ย่า คือ การเลี้ยงผีของเครือญาติ</p>
      </div>

      <p class="section-secondary">- ความเชื่อเรื่องฤกษ์ยาม</p>
      <div class="section-list">
        <p>
          เป็นการหาฤกษ์หายามวันไหนดีวันไหนไม่ดีเป็นความเชื่อของคนไทย
          เมื่อจะทำอะไรก็จะไปหาหมอหาอาจารย์หาวันดีๆ
        </p>
        <p>การสืบชะตา ป่วยบ่อยชะตาไม่ดีก็จะไปหาอาจารย์เพื่อไปสืบชะตา</p>
      </div>

      <p class="section">3. มีพิธีกรรมที่เกี่ยวข้องกับการปลูกลิ้นจี่</p>

      <div class="section-list">
        <P
          >ผ้าป่าลิ้นจี่
          เมื่อเสร็จฤดูเก็บเกี่ยวผลผลิตก็จะไปแก้บนตามที่เราเคยบนไปว่าจะเอาอะไรมาแก้บนและมีการเก็บรวบรวมเงินกันเพื่อซื้อของมาแก้บน
          แล้วก็มากินด้วยกัน พูดคุยเรื่องราวที่ผ่านมาตลอดทั้งปี
          และมีการเก็บเงินเพื่อเอาไปบริจาควัด เพื่อสร้างสถานที่ต่างๆ กุฏิ โบสถ์
          วิหาร</P
        >
        <P
          >การเลี้ยงผีเจ้าที่ เป็นการเลี้ยงเป็นปนะจำทุกปีของใครของมัน
          และจะเลี้ยงตอนเก็บผลผลิตเสร็จ</P
        >
      </div>
      <p class="section">
        ตารางสรุปตำนาน ความเชื่อตำบลศรีถ้อย อำเภอแม่ใจ จังหวัดพะเยา
      </p>
      <div class="section-table">
        <el-table :data="tableData" border>
          <el-table-column prop="legend" label="ตำนาน"> </el-table-column>
          <el-table-column prop="belief" label="ความเชื่อ"> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          legend: "พ่อหลวงคำแดง",
          belief: "ความเชื่อเรื่องผี",
        },
        {
          legend: "ตำนานพระธาตุจอมก๋อ",
          belief: "ความเชื่อเรื่องเทวดา (ท้าวทั้งสี่)",
        },
        {
          legend: "พระพุทธรูปพระเจ้าองค์ดำ",
          belief: "ความเชื่อเกี่ยวกับประเพณี",
        },
        {
          legend: "พระเจ้าทองสัมฤทธิ์",
          belief: "ความเชื่อเกี่ยวกับฤกษ์ยาม",
        },
        {
          legend: "ตำนานหนองเล็งทราย",
          belief: "",
        },
      ],
    };
  },
};
</script>


<style lang="scss" scoped>
.legend {
  .content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;

    .section {
      font-weight: 600;
    }
    .section-secondary {
      font-weight: 600;
      padding-left: 20px;
    }
    .section-list {
      padding-left: 40px;
    }
    .section-image {
      display: flex;
      align-content: center;
      justify-content: center;
      .el-image {
        height: 500px;
      }
    }
  }
}
</style>